html,
body {
  //min-height: 100%;
  overflow-x: auto;
}

body {
  position: relative;

  &>div,
  &>footer {
    color: #000;
  }

  &.no-fixed-supported {
    #dialog-feedback {
      position: absolute;
    }
  }

  input[type="submit"] {
    padding: 0 20px 0px 20px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    font-weight: bold;
    background: #12604b;
    outline: none;
    color: #effcf9;
    border: none;
  }
}

.container {}

@media only screen and (max-width: 600px) {
  #app-layout #header .menu-container>.container {
    flex-direction: column !important;
  }

  #app-layout #header .menu-container {
    height: auto !important;
  }

  #app-layout #header .menu-container>.container #menu a {
    width: 150px;
  }

  #logo {
    margin-bottom: 10px;
  }

  .container {
    width: auto !important;
  }

  table th,
  table td {
    display: block;
  }
}