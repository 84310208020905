#search-form {
    padding: 15px 0;
    margin: 0 auto;

    form {
        display: flex;
        flex-direction: row;

        input {
            display: flex;

            &[type="text"] {
                width: 240px;
            }

            &[type="submit"] {
                margin-left: 10px;
            }
        }
    }
}

#form-add-page {
    input[type="submit"] {
        margin-top: 10px;
        padding: 5px 20px;

        &.disabled {
            background: gray;
        }
    }

    .g-recaptcha {
        margin-top: 10px;
    }

    .form-error {
        // display: block !important;
    }

    .form-control.has-help-txt {
        &+.help-block {
            display: none !important;
        }

        &.error+.help-block {
            display: block !important;
        }
    }
}

form.has-error .form-control,
form.has-error .form-control:focus {
    border-color: #ccc;
}

.red {
    color: red;
}