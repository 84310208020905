@import "variables.less";
@import "init.less";
@import "partials/forms.less";
@import "content.less";

#app-layout {
  font-family: "proxima-nova-regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: @main-bg-color;
  min-height: 100%;

  #header {
    display: flex;
    flex-direction: column;

    .menu-container {
      display: flex;
      height: 50px;
      background: @header-bg-color;

      &>.container {
        display: flex;
        flex-direction: row;

        #logo {
          align-content: flex-start;
          flex-grow: 1;

          img {
            margin-top: 5px;
          }
        }

        #menu {
          display: flex;
          align-content: flex-end;
          align-items: center;

          ul {
            height: 30px;
            margin: 0px;
            padding: 0px;
            list-style: none;
            line-height: normal;
          }

          li {
            display: block;
            float: left;
            margin-right: 5px;
          }

          a {
            display: block;
            float: left;
            padding: 7px 20px 7px 20px;
            text-decoration: none;
            text-align: center;
            text-transform: uppercase;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            font-weight: bold;
            background: #12604b;
            outline: none;
            color: #effcf9;
          }


          a:hover {
            text-decoration: none;
          }
        }
      }
    }

    .search-container {
      display: flex;
      background: @main-bg-color;

      &>.container {
        display: flex;
      }
    }

  }

  #main {
    background: @main-bg-color;

    .panel-default>.panel-heading {
      background-color: initial;
      border-color: initial;
    }

    .breadcrumb {
      background: none;

      a+span {
        font-weight: 700;
      }
    }


    p.description {
      word-break: break-word;
    }
  }

  #footer {
    background: @main-bg-color;
  }

  .copyright {
    text-align: center;
    padding: 10px 0;
  }

  hr {
    border-top: 2px solid #d4d4d4;
  }

  a {
    color: @black-color;

    &:visited {
      color: #12604b;
    }

    &.bold-link {
      font-weight: bold;
    }

    &.go-to {
      padding: 7px 20px 7px 20px;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 11px;
      font-weight: bold;
      background: #12604b;
      outline: none;
      color: #effcf9;
    }
  }
}