html,
body {
  overflow-x: auto;
}
body {
  position: relative;
}
body > div,
body > footer {
  color: #000;
}
body.no-fixed-supported #dialog-feedback {
  position: absolute;
}
body input[type="submit"] {
  padding: 0 20px 0px 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  background: #12604b;
  outline: none;
  color: #effcf9;
  border: none;
}
@media only screen and (max-width: 600px) {
  #app-layout #header .menu-container > .container {
    flex-direction: column !important;
  }
  #app-layout #header .menu-container {
    height: auto !important;
  }
  #app-layout #header .menu-container > .container #menu a {
    width: 150px;
  }
  #logo {
    margin-bottom: 10px;
  }
  .container {
    width: auto !important;
  }
  table th,
  table td {
    display: block;
  }
}
#search-form {
  padding: 15px 0;
  margin: 0 auto;
}
#search-form form {
  display: flex;
  flex-direction: row;
}
#search-form form input {
  display: flex;
}
#search-form form input[type="text"] {
  width: 240px;
}
#search-form form input[type="submit"] {
  margin-left: 10px;
}
#form-add-page input[type="submit"] {
  margin-top: 10px;
  padding: 5px 20px;
}
#form-add-page input[type="submit"].disabled {
  background: gray;
}
#form-add-page .g-recaptcha {
  margin-top: 10px;
}
#form-add-page .form-control.has-help-txt + .help-block {
  display: none !important;
}
#form-add-page .form-control.has-help-txt.error + .help-block {
  display: block !important;
}
form.has-error .form-control,
form.has-error .form-control:focus {
  border-color: #ccc;
}
.red {
  color: red;
}
p.green-color {
  color: green;
}
.gray {
  color: gray;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background: #5cc2a6 !important;
  border-color: #46907c !important;
}
table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
table {
  width: 100%;
  border: none;
  margin-bottom: 18px;
}
table th,
table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border: none;
}
table th {
  font-weight: bold;
}
table thead th {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}
table table {
  background-color: #fff;
}
.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}
table {
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
table caption + thead tr:first-child th,
table caption + tbody tr:first-child th,
table caption + tbody tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + tbody tr:first-child th,
table colgroup + tbody tr:first-child td,
table thead:first-child tr:first-child th,
table tbody:first-child tr:first-child th,
table tbody:first-child tr:first-child td {
  border-top: 0;
}
table thead:first-child tr:first-child > th:first-child,
table tbody:first-child tr:first-child > td:first-child,
table tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table thead:first-child tr:first-child > th:last-child,
table tbody:first-child tr:first-child > td:last-child,
table tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
table thead:last-child tr:last-child > th:first-child,
table tbody:last-child tr:last-child > td:first-child,
table tbody:last-child tr:last-child > th:first-child,
table tfoot:last-child tr:last-child > td:first-child,
table tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}
table thead:last-child tr:last-child > th:last-child,
table tbody:last-child tr:last-child > td:last-child,
table tbody:last-child tr:last-child > th:last-child,
table tfoot:last-child tr:last-child > td:last-child,
table tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}
table tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}
table tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}
table caption + thead tr:first-child th:first-child,
table caption + tbody tr:first-child td:first-child,
table colgroup + thead tr:first-child th:first-child,
table colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}
table caption + thead tr:first-child th:last-child,
table caption + tbody tr:first-child td:last-child,
table colgroup + thead tr:first-child th:last-child,
table colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}
.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}
table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}
table tbody tr.success > td {
  background-color: #dff0d8;
}
table tbody tr.error > td {
  background-color: #f2dede;
}
table tbody tr.warning > td {
  background-color: #fcf8e3;
}
table tbody tr.info > td {
  background-color: #d9edf7;
}
table tbody tr.success:hover > td {
  background-color: #d0e9c6;
}
table tbody tr.error:hover > td {
  background-color: #ebcccc;
}
table tbody tr.warning:hover > td {
  background-color: #faf2cc;
}
table tbody tr.info:hover > td {
  background-color: #c4e3f3;
}
#app-layout {
  font-family: "proxima-nova-regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #effcf9;
  min-height: 100%;
}
#app-layout #header {
  display: flex;
  flex-direction: column;
}
#app-layout #header .menu-container {
  display: flex;
  height: 50px;
  background: #DAF8F0;
}
#app-layout #header .menu-container > .container {
  display: flex;
  flex-direction: row;
}
#app-layout #header .menu-container > .container #logo {
  align-content: flex-start;
  flex-grow: 1;
}
#app-layout #header .menu-container > .container #logo img {
  margin-top: 5px;
}
#app-layout #header .menu-container > .container #menu {
  display: flex;
  align-content: flex-end;
  align-items: center;
}
#app-layout #header .menu-container > .container #menu ul {
  height: 30px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  line-height: normal;
}
#app-layout #header .menu-container > .container #menu li {
  display: block;
  float: left;
  margin-right: 5px;
}
#app-layout #header .menu-container > .container #menu a {
  display: block;
  float: left;
  padding: 7px 20px 7px 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  background: #12604b;
  outline: none;
  color: #effcf9;
}
#app-layout #header .menu-container > .container #menu a:hover {
  text-decoration: none;
}
#app-layout #header .search-container {
  display: flex;
  background: #effcf9;
}
#app-layout #header .search-container > .container {
  display: flex;
}
#app-layout #main {
  background: #effcf9;
}
#app-layout #main .panel-default > .panel-heading {
  background-color: initial;
  border-color: initial;
}
#app-layout #main .breadcrumb {
  background: none;
}
#app-layout #main .breadcrumb a + span {
  font-weight: 700;
}
#app-layout #main p.description {
  word-break: break-word;
}
#app-layout #footer {
  background: #effcf9;
}
#app-layout .copyright {
  text-align: center;
  padding: 10px 0;
}
#app-layout hr {
  border-top: 2px solid #d4d4d4;
}
#app-layout a {
  color: #000000;
}
#app-layout a:visited {
  color: #12604b;
}
#app-layout a.bold-link {
  font-weight: bold;
}
#app-layout a.go-to {
  padding: 7px 20px 7px 20px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  background: #12604b;
  outline: none;
  color: #effcf9;
}
