@import "variables.less";

p {
  &.green-color {
    color: green;
  }
}

.gray {
  color: gray;
}

.page {}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  background: #5cc2a6 !important;
  border-color: #46907c !important;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

table {
  width: 100%;
  border: none;
  margin-bottom: 18px;
}

table th,
table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border: none;
}

table th {
  font-weight: bold;
}

table thead th {
  vertical-align: bottom;
}

table caption+thead tr:first-child th,
table caption+thead tr:first-child td,
table colgroup+thead tr:first-child th,
table colgroup+thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}

table table {
  background-color: #fff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

table {
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

table caption+thead tr:first-child th,
table caption+tbody tr:first-child th,
table caption+tbody tr:first-child td,
table colgroup+thead tr:first-child th,
table colgroup+tbody tr:first-child th,
table colgroup+tbody tr:first-child td,
table thead:first-child tr:first-child th,
table tbody:first-child tr:first-child th,
table tbody:first-child tr:first-child td {
  border-top: 0;
}

table thead:first-child tr:first-child>th:first-child,
table tbody:first-child tr:first-child>td:first-child,
table tbody:first-child tr:first-child>th:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

table thead:first-child tr:first-child>th:last-child,
table tbody:first-child tr:first-child>td:last-child,
table tbody:first-child tr:first-child>th:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}

table thead:last-child tr:last-child>th:first-child,
table tbody:last-child tr:last-child>td:first-child,
table tbody:last-child tr:last-child>th:first-child,
table tfoot:last-child tr:last-child>td:first-child,
table tfoot:last-child tr:last-child>th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
}

table thead:last-child tr:last-child>th:last-child,
table tbody:last-child tr:last-child>td:last-child,
table tbody:last-child tr:last-child>th:last-child,
table tfoot:last-child tr:last-child>td:last-child,
table tfoot:last-child tr:last-child>th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-right-radius: 4px;
}

table tfoot+tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-left-radius: 0;
}

table tfoot+tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  border-bottom-right-radius: 0;
}

table caption+thead tr:first-child th:first-child,
table caption+tbody tr:first-child td:first-child,
table colgroup+thead tr:first-child th:first-child,
table colgroup+tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
}

table caption+thead tr:first-child th:last-child,
table caption+tbody tr:first-child td:last-child,
table colgroup+thead tr:first-child th:last-child,
table colgroup+tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
}

.table-striped tbody>tr:nth-child(odd)>td,
.table-striped tbody>tr:nth-child(odd)>th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover>td,
.table-hover tbody tr:hover>th {
  background-color: #f5f5f5;
}

table td[class*="span"],
table th[class*="span"],
.row-fluid table td[class*="span"],
.row-fluid table th[class*="span"] {
  display: table-cell;
  float: none;
  margin-left: 0;
}

table tbody tr.success>td {
  background-color: #dff0d8;
}

table tbody tr.error>td {
  background-color: #f2dede;
}

table tbody tr.warning>td {
  background-color: #fcf8e3;
}

table tbody tr.info>td {
  background-color: #d9edf7;
}

table tbody tr.success:hover>td {
  background-color: #d0e9c6;
}

table tbody tr.error:hover>td {
  background-color: #ebcccc;
}

table tbody tr.warning:hover>td {
  background-color: #faf2cc;
}

table tbody tr.info:hover>td {
  background-color: #c4e3f3;
}